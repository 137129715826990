import React from "react";
import Layout from "components/Layout";
import TitleWithLine from "../components/TitleWithLine";
import SubpageHeader from "../components/SubpageHeader";
import SectionHeading from "components/SectionHeading";
import Seo from "components/Seo";
import WhiteTile from "../components/WhiteTile";
import "styles/pages/page-reporting.scss";
import classNames from "classnames";

const Reporting = ({ pageContext }) => {
  const rep = pageContext.pageContent;

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/reporting-bg.jpg").default}
      />
      <section className="section-reporting">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-reporting__inner">
                <TitleWithLine variantY="bottom">
                  {rep.reportingTitle}
                </TitleWithLine>
                <p className="section-reporting__text">{rep.reportingText}</p>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={rep.reportingImage?.sourceUrl}
                alt=""
                className="img-fluid section-reporting__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-excel">
        <div className="container">
          <SectionHeading
            title={rep.thisGivesYouTitle}
            subtitle={rep.thisGivesYouSubtitle}
          />
          <div className="row">
            {rep.thisGivesYouTiles.map((item, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  <div className="section-excel__header-wrapper">
                    <TitleWithLine variantY="top">
                      {item.tileTitle}
                    </TitleWithLine>
                  </div>
                  <WhiteTile variant="big">{item.tileText}</WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="section-prodinity">
        <div className="container">
          <SectionHeading title={rep.whyToChooseTitle} />
          <div className="row">
            {rep.whyToChooseTiles.map((item, index) => {
              return (
                <div
                  className={classNames(`col-lg-3`, {
                    [`offset-lg-1`]: index % 3 === 0,
                  })}
                  key={index}
                >
                  <WhiteTile border="bordered">{item.tileText}</WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="section-solution">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-solution__inner">
                <TitleWithLine variantY="bottom">
                  {rep.typeOfSolutionTitle}
                </TitleWithLine>
                <p
                  className="section-solution__text"
                  dangerouslySetInnerHTML={{ __html: rep.typeOfSolutionText }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-2">
              <img
                src={rep.typeOfSolutionImage?.sourceUrl}
                alt=""
                className="img-fluid section-solution__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-packages">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={rep.bespokeSolutionsImage?.sourceUrl}
                alt=""
                className="img-fluid section-packages__img"
              />
            </div>
            <div className="col-lg-5">
              <div className="section-packages__inner">
                <TitleWithLine variantY="bottom">
                  {rep.bespokeSolutionsTitle}
                </TitleWithLine>
                <p
                  dangerouslySetInnerHTML={{ __html: rep.bespokeSolutionsText }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-reviews section-reviews--reporting">
        <div className="container">
          <div className="section-reviews__comparison section-reviews__comparison--reporting">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-item">
                  <div className="single-item__title">
                    {rep.customizedSolutionTitle}
                  </div>
                  <div className="single-item__tile single-item__tile--shadow">
                    {rep.customizedSolutionListNew.map((item, index) => {
                      return (
                        <div className="single-item__tile-text" key={index}>
                          {item.listItem}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-item">
                  <div className="single-item__title">
                    {rep.offTheShelfTitle}
                  </div>
                  <div className="single-item__tile single-item__tile--shadow">
                    {rep.offTheShelfListNew.map((item, index) => {
                      return (
                        <div
                          className="single-item__tile-text single-item__tile-text--error"
                          key={index}
                        >
                          {item.listItem}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Reporting;
